import { SiteList } from './components/SiteList';

import './styles/global.scss';


export function App(){

    // throw new Error('Eita Giovana, o forninho caiu!');
    return (
        <>
            <SiteList />
            
        </>
    )
}
